export function singleworksFunc() {
  const nav = document.querySelector("#navArea");
  const btn = document.querySelector(".l-hamburger__button");

  btn.addEventListener("click", function () {
    nav.classList.toggle("open");
  });

  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-single-works__detail").getBoundingClientRect()
        .top + window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  const navs = document.querySelector("#navAreas");
  const btns = document.querySelector(".l-hamburgers__button");
  const menu = document.querySelector(".l-hamburgers__menu");

  btns.addEventListener("click", () => {
    navs.classList.toggle("open");
    menu.classList.toggle("push");
  });

  ///////////////////////////////////////////////////////////////////////

  /////////////////////Progress bar///////////////////

  window.addEventListener("scroll", () => {
    const h1 = window.pageYOffset;
    const h2 = window.innerHeight;
    const h3 = document.documentElement.offsetHeight;
    const prg = document.querySelector("#progress-vertical");
    const v = parseInt((h1 / (h3 - h2)) * 100);
    prg.value = v;
  });

  // ページ遷移 Progressbar
}
