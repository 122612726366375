export function lifeFunc() {
  ///////////////////////画像変更/////////////////////////

  function wineup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_2.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function wineout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }

  function adventureup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_3.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function adventureout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }

  function bookup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_4.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function bookout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  function musicup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_5.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function musicout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  function visionup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_vision.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function visionout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  function podcastup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_1.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function podcastout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  function blogup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_7.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function blogout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  function dreamup() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_dream.png"; // 画像を変更する
  }

  //マウスアウト時の処理を記述
  function dreamout() {
    let pic = document.getElementById("pic");
    pic.src =
      "https://maisonishihara.com/wp-content/themes/maisonishihara33/assets/images/life/img_life_0.png"; // 画像を元に戻す
  }
  document.addEventListener("DOMContentLoaded", function () {
    // #life-dream リンクに関数を紐づける
    let dreamLink = document.querySelector("#life-dream");
    dreamLink.addEventListener("mouseover", dreamup);
    dreamLink.addEventListener("mouseout", dreamout);

    let visionLink = document.querySelector("#life-vision");
    visionLink.addEventListener("mouseover", visionup);
    visionLink.addEventListener("mouseout", visionout);

    let bookLink = document.querySelector("#life-book");
    bookLink.addEventListener("mouseover", bookup);
    bookLink.addEventListener("mouseout", bookout);

    let musicLink = document.querySelector("#life-music");
    musicLink.addEventListener("mouseover", musicup);
    musicLink.addEventListener("mouseout", musicout);

    let podcastLink = document.querySelector("#life-podcast");
    podcastLink.addEventListener("mouseover", podcastup);
    podcastLink.addEventListener("mouseout", podcastout);

    let link = document.querySelector("#life-adventure");
    link.addEventListener("mouseover", adventureup);
    link.addEventListener("mouseout", adventureout);

    let wineLink = document.querySelector("#life-wine");
    wineLink.addEventListener("mouseover", wineup);
    wineLink.addEventListener("mouseout", wineout);

    let blogLink = document.querySelector("#life-blog");
    blogLink.addEventListener("mouseover", blogup);
    blogLink.addEventListener("mouseout", blogout);
  });
}
