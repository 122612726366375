export function contactFunc() {
  // const nav = document.querySelector("#navArea");
  // const btn = document.querySelector(".l-hamburger__button");

  // btn.addEventListener("click", function () {
  //   nav.classList.toggle("open");
  // });

  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-contact__down").getBoundingClientRect().top +
      window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  const navs = document.querySelector("#navAreas");
  const btns = document.querySelector(".l-hamburgers__button");
  const menu = document.querySelector(".l-hamburgers__menu");

  btns.addEventListener("click", () => {
    navs.classList.toggle("open");
    menu.classList.toggle("push");
  });

  ///////////////////////////////////////////////////////////////////////
  /**
   * Footer Time Zone
   */

  document.addEventListener("DOMContentLoaded", function () {
    initTimeZone();
  });
  function initTimeZone() {
    // Time zone
    // https://stackoverflow.com/questions/39418405/making-a-live-clock-in-javascript/67149791#67149791
    // https://stackoverflow.com/questions/8207655/get-time-of-specific-timezone
    // https://stackoverflow.com/questions/63572780/how-to-update-intl-datetimeformat-with-new-date

    const timeSpan = document.querySelector("#timeSpan");

    const optionsTime = {
      timeZone: "Asia/Tokyo",
      // year: 'numeric',
      // month: 'numeric',
      // day: 'numeric',
      hour: "2-digit",
      hour12: false,
      minute: "numeric",
      // second: 'numeric',
    };

    const formatter = new Intl.DateTimeFormat([], optionsTime);
    updateTime();
    setInterval(updateTime, 1000);

    function updateTime() {
      const dateTime = new Date();
      const formattedDateTime = dateTime.toLocaleTimeString([], optionsTime);
      timeSpan.textContent = formattedDateTime;
    }
  }
}
