export function aboutFunc() {
  // const nav = document.querySelector("#navArea");
  // const btn = document.querySelector(".l-hamburger__button");

  // btn.addEventListener("click", function () {
  //   nav.classList.toggle("open");
  // });
  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-about__first-down").getBoundingClientRect()
        .top + window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  const navs = document.querySelector("#navAreas");
  const btns = document.querySelector(".l-hamburgers__button");
  const menu = document.querySelector(".l-hamburgers__menu");

  btns.addEventListener("click", () => {
    navs.classList.toggle("open");
    menu.classList.toggle("push");
  });
}
