//トップページ
export function homeFunc() {
  const loadedFlag = "loaded";

  // ウィンドウ読み込み時
  window.onload = function () {
    // フラグが立っていない場合のみ処理を実行
    if (!localStorage.getItem(loadedFlag)) {
      // ローディングアニメーションの処理

      const progressBar = document.querySelector(".js-loader__progress-bar");
      const progressNumber = document.querySelector(
        ".js-loader__progress-number"
      );

      const imgLoad = imagesLoaded("body"); //body内の画像を監視
      const imgTotal = imgLoad.images.length; //画像の総数を取得

      let imgLoaded = 0; //ロードした画像カウント

      // アニメーションの表示時間（ミリ秒）
      const animationDuration = 5000; // 5秒間
      const progressBarDuration = 3000; // プログレスバーの進捗にかかる時間（ミリ秒）

      // ローディングアニメーションの開始
      const animationStart = new Date().getTime();

      // アニメーションが表示中かどうかのフラグ
      let animationInProgress = true;

      // ローディング進捗状況をアップデート
      function updateProgress() {
        // 現在の時間
        const currentTime = new Date().getTime();
        // アニメーションが表示されてからの経過時間
        const elapsedTime = currentTime - animationStart;

        // アニメーション表示中かつ5秒以上経過した場合は終了
        if (animationInProgress && elapsedTime >= animationDuration) {
          animationInProgress = false;
          // ローディング完了後、フラグを立てる
          localStorage.setItem(loadedFlag, "1");
          // アニメーション終了後、1秒後にローディングアニメーションを非表示にする
          setTimeout(function () {
            document.querySelector("body").classList.add("is-loaded");
          }, 1000);
        } else {
          // プログレスバーの進捗を計算
          let progressResult = (elapsedTime / progressBarDuration) * 100;
          // 進捗が100を超えないように制限
          progressResult = Math.min(progressResult, 100);
          // プログレスバーと数字で表示
          progressBar.style.width = progressResult + "%";
          progressNumber.innerText = Math.floor(progressResult);
        }
      }

      // 読み込み状況をアップデート
      let progressInit = setInterval(function () {
        updateProgress();
      }, 40);

      // 画像をロードするたびにカウントをアップ
      imgLoad.on("progress", function (instance, image) {
        imgLoaded++;
      });
    } else {
      // 2回目以降の訪問時はすぐにローディングアニメーションを非表示にする
      document.querySelector("body").classList.add("is-loaded");
    }
  };

  //////////////////////////////////////////////////////

  const navAreas = document.querySelector("#navAreas");
  const hamburgersButton = document.querySelector(".l-hamburgers__button");
  const hamburgersMenu = document.querySelector(".l-hamburgers__menu");
  hamburgersButton.addEventListener("click", () => {
    navAreas.classList.toggle("open");
    hamburgersMenu.classList.toggle("push");
  });

  window.addEventListener("scroll", function () {
    const adjust = 120;
    const pos =
      document.querySelector(".p-home__email").getBoundingClientRect().top +
      window.pageYOffset;
    /* mvを過ぎたmainタグの高さを取得して変数[pos]に格納 */
    if (window.pageYOffset + adjust > pos) {
      /* 変数[pos]より、スクロールされていたら */
      document.querySelector(".l-hamburgers__button").classList.add("open");
    } else {
      /* それ以外の場合 */
      document.querySelector(".l-hamburgers__button").classList.remove("open");
    }
  });

  ///////////////////////////////////////////////////////////////////////

  //////////////////////背景色・文字色変更///////////////////
  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__comfortable").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__comfortable").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });

  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__third-page").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__third-page").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });

  $(function () {
    $(".p-home__third-card").hover(
      function () {
        $(".p-home__forth-page").addClass("open"); //hoverしたメニューにopenを付与
      },
      function () {
        $(".p-home__forth-page").removeClass("open"); //hoverしたメニューにopenを付与
      }
    );
  });
  //////////////////////////
}
